<template>
  <!--srcviewscompanydetailsbusinessvuehtmlStart-->

  <div id="defaultId1">
    <div id="cc18ed" class="udb507 u22483">

      <div id="g2ede8" style="margin-top:10px">


        <div style="display:flex">
          <div class="operations-box-lefttwo" style="width:45%;margin-right:10px">
            <h2 id="gf7b16" class="operations-head " style="margin-bottom:1.4285714285714286rem">
              Industry Statistics
            </h2>
            <div class="flex_center_between_box">
              <el-select ref="upSelect3" v-click-outside="clickOutSide3" popper-class="common_select" v-model="value1" filterable clearable placeholder="Select a Year"
                @change="selectType" style="width: 40%; font-size: 1.4285714285714286rem">
                <el-option v-for="item in YeraOptions" :key="item.id" :label="item.name" width="21.428571428571427rem" :value="item.id">
                </el-option>
              </el-select>

              <el-select      ref="upSelect4" v-click-outside="clickOutSide4"  :disabled="iconactive == 'computer'" popper-class="common_select" v-model="value2" clearable
                filterable width="60%" @change="selectType"
                style="margin-left: 1.4285714285714286rem; width: 60%; font-size: 1.4285714285714286rem;height: 3.5714285714285716rem;" placeholder="Select a type">
                <el-option v-for="item in options" :key="item.id" :label="item.name" width="21.428571428571427rem" height="3.5714285714285716rem"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <el-table empty-text="No Data" :data="tableData" style="width: 100%" row-class-name="highlight-row"
              :cell-class-name="cellClassName" class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column label="" prop="name" style="height: 4.142857142857143rem">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Subiect company" align="center" prop="SubiectCompany" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{
                    scope.row.subjectCompany
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Companies in the same industry" align="right" prop="sameIndustry"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content" v-if=" scope.row.sameIndustry!='null'">{{
                    scope.row.sameIndustry | FilterNum
                  }}</span>
                      
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div id="ge6845" class="uc1096" label="" :isShowLabel="false" style="width: 55%">
            <div class="operations-box-right operations-box-lefttwo" >
              <div id="gf7b16" class="operations-head operations-headtwo">
                <span class="ViewMore contclass">
                  <div :class="iconactive == 'computer' ? 'svgbg svgclass' : 'svgclass'
                    ">
                    <svg t="1719206471849" v-if="iconactive == 'computer'" class="icon" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4712" width="2.28rem" height="2.28rem">
                      <path
                        d="M136.3 811.8h122.2c34.6 0 62.7-28.1 62.7-62.7V504.7c0-34.6-28.1-62.7-62.7-62.7H136.3c-34.6 0-62.7 28.1-62.7 62.7v244.4c0 34.6 28.1 62.7 62.7 62.7z m0-325.8h122.2c10.3 0 18.7 8.4 18.7 18.7v244.4c0 10.3-8.4 18.7-18.7 18.7H136.3c-10.3 0-18.7-8.4-18.7-18.7V504.7c0-10.3 8.4-18.7 18.7-18.7zM462.1 811.8h122.2c34.6 0 62.7-28.1 62.7-62.7v-448c0-34.6-28.1-62.7-62.7-62.7H462.1c-34.6 0-62.7 28.1-62.7 62.7v448c0 34.6 28.1 62.7 62.7 62.7z m-18.7-510.7c0-10.3 8.4-18.7 18.7-18.7h122.2c10.3 0 18.7 8.4 18.7 18.7v448c0 10.3-8.4 18.7-18.7 18.7H462.1c-10.3 0-18.7-8.4-18.7-18.7v-448zM910.1 34.8H787.9c-34.6 0-62.7 28.1-62.7 62.7v651.6c0 34.6 28.1 62.7 62.7 62.7h122.2c34.6 0 62.7-28.1 62.7-62.7V97.5c0-34.6-28.1-62.7-62.7-62.7z m18.7 714.3c0 10.3-8.4 18.7-18.7 18.7H787.9c-10.3 0-18.7-8.4-18.7-18.7V97.5c0-10.3 8.4-18.7 18.7-18.7h122.2c10.3 0 18.7 8.4 18.7 18.7v651.6zM950.8 869.6H95.6c-12.2 0-22 9.8-22 22s9.8 22 22 22h855.2c12.2 0 22-9.8 22-22s-9.8-22-22-22z"
                        p-id="4713" fill="#1296db"></path>
                    </svg>

                    <svg t="1719206471849" v-else @click="iconactive = 'computer'" class="icon" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4712" width="2.28rem" height="2.28rem"
                      data-spm-anchor-id="a313x.manage_type_myprojects.0.i6.6bfc3a81jbWY9R">
                      <path
                        d="M136.3 811.8h122.2c34.6 0 62.7-28.1 62.7-62.7V504.7c0-34.6-28.1-62.7-62.7-62.7H136.3c-34.6 0-62.7 28.1-62.7 62.7v244.4c0 34.6 28.1 62.7 62.7 62.7z m0-325.8h122.2c10.3 0 18.7 8.4 18.7 18.7v244.4c0 10.3-8.4 18.7-18.7 18.7H136.3c-10.3 0-18.7-8.4-18.7-18.7V504.7c0-10.3 8.4-18.7 18.7-18.7zM462.1 811.8h122.2c34.6 0 62.7-28.1 62.7-62.7v-448c0-34.6-28.1-62.7-62.7-62.7H462.1c-34.6 0-62.7 28.1-62.7 62.7v448c0 34.6 28.1 62.7 62.7 62.7z m-18.7-510.7c0-10.3 8.4-18.7 18.7-18.7h122.2c10.3 0 18.7 8.4 18.7 18.7v448c0 10.3-8.4 18.7-18.7 18.7H462.1c-10.3 0-18.7-8.4-18.7-18.7v-448zM910.1 34.8H787.9c-34.6 0-62.7 28.1-62.7 62.7v651.6c0 34.6 28.1 62.7 62.7 62.7h122.2c34.6 0 62.7-28.1 62.7-62.7V97.5c0-34.6-28.1-62.7-62.7-62.7z m18.7 714.3c0 10.3-8.4 18.7-18.7 18.7H787.9c-10.3 0-18.7-8.4-18.7-18.7V97.5c0-10.3 8.4-18.7 18.7-18.7h122.2c10.3 0 18.7 8.4 18.7 18.7v651.6zM950.8 869.6H95.6c-12.2 0-22 9.8-22 22s9.8 22 22 22h855.2c12.2 0 22-9.8 22-22s-9.8-22-22-22z"
                        p-id="4713" fill="#515151"></path>
                    </svg>
                  </div>
                  <div :class="iconactive == 'table' ? 'svgbg svgclass' : 'svgclass'
                    ">
                    <svg v-if="iconactive == 'table'" t="1719206731243" class="icon" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5149" width="32" height="32">
                      <path
                        d="M289.3 437.1c0 41.1 33.3 74.5 74.5 74.5s74.5-33.3 74.5-74.5-33.3-74.5-74.5-74.5-74.5 33.3-74.5 74.5zM229.7 675.4c0 24.7 20 44.7 44.7 44.7s44.7-20 44.7-44.7c0-24.7-20-44.7-44.7-44.7-24.7 0-44.7 20.1-44.7 44.7zM736.2 556.3c0 24.7 20 44.7 44.7 44.7s44.7-20 44.7-44.7-20-44.7-44.7-44.7-44.7 20-44.7 44.7zM527.6 407.3c0 24.7 20 44.7 44.7 44.7 24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7s-44.7 20-44.7 44.7zM587.2 228.5c0 24.7 20 44.7 44.7 44.7 24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7s-44.7 20-44.7 44.7zM512.7 608.4c0 49.4 40 89.4 89.4 89.4s89.4-40 89.4-89.4-40-89.4-89.4-89.4-89.4 40-89.4 89.4zM780.9 250.9c0 41.1 33.3 74.5 74.5 74.5s74.5-33.3 74.5-74.5-33.3-74.5-74.5-74.5-74.5 33.3-74.5 74.5z"
                        p-id="5150" fill="#1290C9"></path>
                      <path
                        d="M928 832H128V161c0-17.6-14.4-32-32-32s-32 14.4-32 32v703c0 17.6 14.4 32 32 32h832c17.6 0 32-14.4 32-32s-14.4-32-32-32z"
                        p-id="5151" fill="#1290C9"></path>
                    </svg>

                    <svg @click="iconactive = 'table'" v-else t="1719206731243" class="icon" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5149" width="32" height="32">
                      <path
                        d="M289.3 437.1c0 41.1 33.3 74.5 74.5 74.5s74.5-33.3 74.5-74.5-33.3-74.5-74.5-74.5-74.5 33.3-74.5 74.5zM229.7 675.4c0 24.7 20 44.7 44.7 44.7s44.7-20 44.7-44.7c0-24.7-20-44.7-44.7-44.7-24.7 0-44.7 20.1-44.7 44.7zM736.2 556.3c0 24.7 20 44.7 44.7 44.7s44.7-20 44.7-44.7-20-44.7-44.7-44.7-44.7 20-44.7 44.7zM527.6 407.3c0 24.7 20 44.7 44.7 44.7 24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7s-44.7 20-44.7 44.7zM587.2 228.5c0 24.7 20 44.7 44.7 44.7 24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7s-44.7 20-44.7 44.7zM512.7 608.4c0 49.4 40 89.4 89.4 89.4s89.4-40 89.4-89.4-40-89.4-89.4-89.4-89.4 40-89.4 89.4zM780.9 250.9c0 41.1 33.3 74.5 74.5 74.5s74.5-33.3 74.5-74.5-33.3-74.5-74.5-74.5-74.5 33.3-74.5 74.5z"
                        p-id="5150" fill="#8497AB"></path>
                      <path
                        d="M928 832H128V161c0-17.6-14.4-32-32-32s-32 14.4-32 32v703c0 17.6 14.4 32 32 32h832c17.6 0 32-14.4 32-32s-14.4-32-32-32z"
                        p-id="5151" fill="#8497AB"></path>
                    </svg>
                  </div>
                </span>
              </div>
              <div class="juxingtu juxing" v-show="iconactive == 'table'" ref="SupplyChainEchart"
                >
                <!-- style="width: 70.17rem; height: 35.71rem; margin-left: -1rem" -->
              </div>
              <div class="juxingtu tiaoxing" v-show="iconactive == 'computer'" ref="IndustryStatisticschart"
                style=""></div>
                <!-- width: 70.17rem; height: 35.71rem; margin-left:  -1rem -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCompanyIndustry,
  getIndustryStatics
} from "@/api/companyApi";
export default {

  name: "",
  //   props: {},

  components: {},
  data() {
    return {
      Busines: '',

      ProductsServices: '',

      options: [],
      YeraOptions: [],
      value1: "2023",
      value2: "",
      tableData: [

      ],
      data: [
        [3.275154, 2.957587],
        [-3.344465, 2.603513],
        [0.355083, -3.376585],
        [1.852435, 3.547351],
        [-2.078973, 2.552013],
        [-0.993756, -0.884433],
        [2.682252, 4.007573],
        [-3.087776, 2.878713],
        [-1.565978, -1.256985],
        [2.441611, 0.444826],
        [-0.659487, 3.111284],
        [-0.459601, -2.618005],
        [2.17768, 2.387793],
        [-2.920969, 2.917485],
        [-0.028814, -4.168078],
        [3.625746, 2.119041],
        [-3.912363, 1.325108],
        [-0.551694, -2.814223],
        [2.855808, 3.483301],
        [-3.594448, 2.856651],
        [0.421993, -2.372646],
        [1.650821, 3.407572],
        [-2.082902, 3.384412],
        [-0.718809, -2.492514],
        [4.513623, 3.841029],
        [-4.822011, 4.607049],
        [-0.656297, -1.449872],
        [1.919901, 4.439368],
        [-3.287749, 3.918836],
        [-1.576936, -2.977622],
        [3.598143, 1.97597],
        [-3.977329, 4.900932],
        [-1.79108, -2.184517],
        [3.914654, 3.559303],
        [-1.910108, 4.166946],
        [-1.226597, -3.317889],
        [1.148946, 3.345138],
        [-2.113864, 3.548172],
        [0.845762, -3.589788],
        [2.629062, 3.535831],
        [-1.640717, 2.990517],
        [-1.881012, -2.485405],
        [4.606999, 3.510312],
        [-4.366462, 4.023316],
        [0.765015, -3.00127],
        [3.121904, 2.173988],
        [-4.025139, 4.65231],
        [-0.559558, -3.840539],

      ],

      iconactive: "computer",
      inputIndex: -1,
      editcontent: "",
      IndustryDescription: '',
      ISICCode: '',
      NACECode: '',
      Employees: '',
      NAICSCode: '',
      isicCode: '',
      selectTypevalue: '',
      // insertData
      // srcviewscompanydetailsbusinessvueDataStart

      // hangyefenxiKey
      hangyefenxiKey: 0,
      echartsdata: []
      // srcviewscompanydetailsbusinessvueDataEnd
    };
  },
  methods: {
    clickOutSide3() {

if (this.$refs.upSelect3) {
  this.$refs.upSelect3.visible = false

}

},
clickOutSide4() {

if (this.$refs.upSelect4) {
  this.$refs.upSelect4.visible = false

}

},
    async selectType() {
      
      this.options.map(item => {
        if (item.id == this.value2) {
          this.selectTypevalue = item.name
        }
      })
      getIndustryStatics({
        aaaId: this.$route.query.id3a,
        industryCode: this.isicCode,
        filter: this.value2,
        year: this.value1
      }).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.staticItems
          let dotCharts = res.data.dotCharts
          dotCharts.map(item => {
            if (this.value2 == '4') {
              if (item.yAxis == '3A1') {
                item.Create = 'AAA'
              }
              if (item.yAxis == '3A2') {
                item.Create = 'AA+'
              }
              if (item.yAxis == '3A3') {
                item.Create = 'AA'
              }
              if (item.yAxis == '3A4') {
                item.Create = 'AA-'
              }
              if (item.yAxis == '3A5') {
                item.Create = 'A+'
              }
              if (item.yAxis == '3A6') {
                item.Create = 'A'
              }
              if (item.yAxis == '3A7') {
                item.Create = 'A-'
              }
              if (item.yAxis == '3A8') {
                item.Create = 'BBB+'
              }
              if (item.yAxis == '3A9') {
                item.Create = 'BBB'
              }
              if (item.yAxis == '3A10') {
                item.Create = 'BBB-'
              }
              if (item.yAxis == '3A11') {
                item.Create = 'BB+'
              }
              if (item.yAxis == '3A12') {
                item.Create = 'BB'
              }
              if (item.yAxis == '3A13') {
                item.Create = 'BB-'
              }
              if (item.yAxis == '3A14') {
                item.Create = 'B+'
              }
              if (item.yAxis == '3A15') {
                item.Create = 'B'
              }
              if (item.yAxis == '3A16') {
                item.Create = 'B-'
              }
              if (item.yAxis == '3A17') {
                item.Create = 'CCC'
              }
              if (item.yAxis == '3A18') {
                item.Create = 'CC'
              }
              if (item.yAxis == '3A19') {
                item.Create = 'C'
              }
            } else {
              item.Create = item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : ''
            }

          })
          // 收入
          let xAxisnum = dotCharts.filter(i => i.financialIndex == 'income' && i.xAxis > 0).map(item => {
            return [item.xAxis * 10000, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          //  利润
          let yAxisnum = dotCharts.filter(i => i.financialIndex == 'profit' && i.xAxis > 0).map(item => {
            return [item.xAxis * 10000, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          // 总资产
          let zAxisnum = dotCharts.filter(i => i.financialIndex == 'asset' && i.xAxis > 0).map(item => {
            return [item.xAxis * 10000, item.yAxis.replace('3A', '') ? item.yAxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          // 资产回报率
          // let wAxisnum = dotCharts.filter(i => i.financialIndex == 'roa' && i.xaxis > 0).map(item => {
          //   return [item.xaxis, item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : '', item.num, 0]
          // })

          const data = [xAxisnum, yAxisnum, zAxisnum,]

          this.echartsdata = data
          this.$nextTick(() => {
            // 在 DOM 更新后执行代码
              // setTimeout(()=>{
                
              //   this.industryEchart();
              
              //   this.IndustryStatisticschart()
              // },200)
              
          });
        }

      })


    },
    getList() {
      
      getCompanyIndustry({
        id3a: this.$route.query.id3a,
      }).then(res => {
        if (res.code == 200) {
          
          this.IndustryDescription = res.data.industryDescription
          this.ISICCode = res.data.isicCodeDes
          this.isicCode = res.data.isicCode
          this.NAICSCode = res.data.naicsCodeDes
          this.NACECode = res.data.naceCodeDes
          this.Employees = res.data.employees
          this.YeraOptions = res.data.yearFilter
          this.value1 = this.YeraOptions[0].id
          this.options = res.data.selectConditions
          this.value2 = res.data.selectConditions[0].id
          this.selectTypevalue = res.data.selectConditions[0].name
          this.tableData = res.data.staticItems
          let dotCharts = res.data.dotCharts
          dotCharts.map(item => {
            if (this.value2 == '4') {
              if (item.yAxis == '3A1') {
                item.Create = 'AAA'
              }
              if (item.yAxis == '3A2') {
                item.Create = 'AA+'
              }
              if (item.yAxis == '3A3') {
                item.Create = 'AA'
              }
              if (item.yAxis == '3A4') {
                item.Create = 'AA-'
              }
              if (item.yAxis == '3A5') {
                item.Create = 'A+'
              }
              if (item.yAxis == '3A6') {
                item.Create = 'A'
              }
              if (item.yAxis == '3A7') {
                item.Create = 'A-'
              }
              if (item.yAxis == '3A8') {
                item.Create = 'BBB+'
              }
              if (item.yAxis == '3A9') {
                item.Create = 'BBB'
              }
              if (item.yAxis == '3A10') {
                item.Create = 'BBB-'
              }
              if (item.yAxis == '3A11') {
                item.Create = 'BB+'
              }
              if (item.yAxis == '3A12') {
                item.Create = 'BB'
              }
              if (item.yAxis == '3A13') {
                item.Create = 'BB-'
              }
              if (item.yAxis == '3A14') {
                item.Create = 'B+'
              }
              if (item.yAxis == '3A15') {
                item.Create = 'B'
              }
              if (item.yAxis == '3A16') {
                item.Create = 'B-'
              }
              if (item.yAxis == '3A17') {
                item.Create = 'CCC'
              }
              if (item.yAxis == '3A18') {
                item.Create = 'CC'
              }
              if (item.yAxis == '3A19') {
                item.Create = 'C'
              }
            } else {
              item.Create = item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : ''
            }

          })
          // 收入
          let xAxisnum = dotCharts.filter(i => i.financialIndex == 'income' && i.xaxis > 0).map(item => {
            return [item.xaxis * 10000, item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          //  利润
          let yAxisnum = dotCharts.filter(i => i.financialIndex == 'profit' && i.xaxis > 0).map(item => {
            return [item.xaxis * 10000, item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          // 总资产
          let zAxisnum = dotCharts.filter(i => i.financialIndex == 'asset' && i.xaxis > 0).map(item => {
            return [item.xaxis * 10000, item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : '', item.num, 0, item.Create]
          })
          // 资产回报率
          // let wAxisnum = dotCharts.filter(i => i.financialIndex == 'roa' && i.xaxis > 0).map(item => {
          //   return [item.xaxis, item.yaxis.replace('3A', '') ? item.yaxis.replace('3A', '') : '', item.num, 0]
          // })

          const data = [xAxisnum, yAxisnum, zAxisnum,]
          this.echartsdata = data
          
          this.$nextTick(() => {
            // 在 DOM 更新后执行代码
            setTimeout(()=>{ // 等待DOM更新,表格渲染太慢了再加一些时间
            
              this.industryEchart();
              this.IndustryStatisticschart()
            },200)
           
          });
        } else {

        }

      })
    },

    edit(content) {
      this.editcontent = content;

      //   this.tableData.push({ ...this.tableData[index-1] });
    },
    // insertMethod
    // srcviewscompanydetailsbusinessvueMethodStart
    // returnPage
    handleEdit(index, row) {
    },
    async IndustryStatisticschart() {
      let dataone = [];
      let datanum = [];
      // await this.$nextTick(); // 等待DOM更新
      this.tableData.map(item => {
        dataone.push(item.subjectCompany)
        datanum.push(item.sameIndustry)
      })
   
      let that = this;

      
      this.echartsBox = this.$echarts.init(this.$refs.IndustryStatisticschart);
      this.echartsBox.resize()
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: dataone,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              // 设置文本的旋转角度
              rotate: 0,
              // 设置文本的最大宽度
              width: 130,
              // 使用 formatter 进行文本处理
              formatter: function (value) {
                // 如果文本长度超过一定值，则进行省略
                return value.length > 5 ? value.substring(0, 5) + '...' : value;
              }
            }
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: datanum,
            barWidth: "80px", // 柱子宽度
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      this.echartsBox.setOption(option);
    },
    returnPage() {
      history.go(-1);
    }, // reShowPopver1
    reShowPopver1() {
      ;
      this.$refs.myPopover.doClose();

      this.$nextTick(() => {
        this.$refs.myPopover.doShow();
      });
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "highlight-cell"; // 设置你想要的背景色
      }
    },
    addInput(index) {
      this.inputIndex = index;
      this.tableData.push({ ...this.tableData[index - 1] });
    },
    initCharStyle(){ // 初始化图表的样式
      
      
      let wt = document.querySelector('.tiaoxing').getBoundingClientRect().width
      let ht = document.querySelector('.tiaoxing').getBoundingClientRect().height
      document.querySelector('.tiaoxing').style.width = wt + 'px' // 强制添加样式
      document.querySelector('.tiaoxing').style.height = ht + 'px'
      document.querySelector('.juxing').style.width = wt + 'px' // 强制添加样式
      document.querySelector('.juxing').style.height = ht + 'px'
    },
    async industryEchart() {
      let data = this.echartsdata
      let that = this;
      
      this.initCharStyle()
      await this.$nextTick(); // 等待DOM更新
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      let copyCompany = JSON.parse(localStorage.getItem('CompanyBase')).companyName

      const option = {
        grid: {
          left: '3%',
          right: '5%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },
        color: ['#0064d2', '#f5af02', '#86b817',],
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {

            if (params.color === '#DD5145') {
              if (params.value[0] < 1000000) {
                return 'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'k<br/>') +
                  that.selectTypevalue +
                  '' + params.value[1]
              } else if (params.value[0] >= 1000000 && params.value[0] < 1000000000) {
                return 'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'M<br/>') +
                  that.selectTypevalue +
                  '' + params.value[1]
              } else if (params.value[0] >= 1000000000 && params.value[0] < 1000000000000) {
                return 'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' +
                  (params.value[0] / 1000000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'B<br/>') +
                  that.selectTypevalue +
                  '' + params.value[1]
              } else {
                return 'Subject Company: ' + copyCompany + '<br/>' + params.seriesName + '：' + params.value[0] + '~' +
                  (params.value[0] / 1000000000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'B<br/>') +
                  that.selectTypevalue +
                  '' + params.value[1]
              }
            } else {
              if (params.value[0] < 1000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'k<br/>') + that.selectTypevalue + ': ' +

                  '' + params.value[4] + '<br/>' +
                  'Number of Company: ' + params.value[2]
              } else if (params.value[0] >= 1000000 && params.value[0] < 1000000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'M<br/>') + that.selectTypevalue + ': ' +

                  '' + params.value[4] + '<br/>' +
                  'Number of Company: ' + params.value[2]
              } else if (params.value[0] >= 1000000000 && params.value[0] < 1000000000000) {
                return params.seriesName + '：' +
                  (params.value[0] / 1000000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'B<br/>') + that.selectTypevalue + ': ' +
                  '' + params.value[4] + '<br/>' +
                  'Number of Company: ' + params.value[2]
              } else {
                return params.seriesName + '：' + params.value[0] + '~' +
                  (params.value[0] / 1000000000000).toFixed(2) + (params.seriesName == 'roa' ? '%<br/>' : 'B<br/>') + that.selectTypevalue + ': ' +

                  '' + params.value[4] + '<br/>' +
                  'Number of Company: ' + params.value[2]
              }
            }
          }
        },
        xAxis: {
          show: true,
          type: 'log',
          max: 100000000,
          min: 1000,

          scale: true,
          axisLabel: {
            show: true,
            // formatter: '{value}',
            formatter: (value) => {
              if (value < 1000000) {
                return value / 1000 + 'k'
              } else if (value < 1000000000) {
                return value / 1000000 + 'm'
              } else if (value > 1000000000000) {
                return value / 1000000000 + 'b'
              } else {
                return value / 1000000000 + 'b'
              }
            },
            color: '#8398BB'
          },
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          // max: 19,
          // min: 1,
          min: that.value2 == '2' ? '2010' : 1,
          // splitNumber: 4,
          inverse: true,
          splitLine: {
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#8398BB'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#142746'
            }
          },
          axisLabel: {
            fontSize: '1.285714rem',
            color: '#8398BB',
            // 后期单位
            formatter: function (value) {
              if (that.value2 == '4') {
                if (value == 1) {
                  return 'AAA';
                }
                if (value == 2) {
                  return 'AA+';
                }
                if (value == 3) {
                  return 'AA';
                }
                if (value == 4) {
                  return 'AA-';
                }
                if (value == 5) {
                  return 'A+';
                }
                if (value == 6) {
                  return 'A';
                }
                if (value == 7) {
                  return 'A-';
                }
                if (value == 8) {
                  return 'BBB+';
                }
                if (value == 9) {
                  return 'BBB';
                }
                if (value == 10) {
                  return 'BBB-';
                }
                if (value == 11) {
                  return 'BB+';
                }
                if (value == 12) {
                  return 'BB';
                }
                if (value == 13) {
                  return 'BB-';
                }
                if (value == 14) {
                  return 'B+';
                }
                if (value == 15) {
                  return 'B';
                }
                if (value == 16) {
                  return 'B-';
                }
                if (value == 17) {
                  return 'CCC';
                }
                if (value == 18) {
                  return 'CC';
                }
                if (value == 19) {
                  return 'C';
                }
              } else {
                return value;
              }

              // 不进行千分位格式化
            }
          },
          axisTick: {
            show: false
          }
        },
        legend: {
          right: '10%',
          data: ['Revenue', 'Earning', 'Total Assets',],
          textStyle: {
            fontSize: 14
          }
        },
        series: [
          {
            name: 'Revenue',
            data: data[0],
            type: 'scatter',
            itemStyle: {
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#DD5145'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 12
              }
              return 8
            }
          },
          {
            name: 'Earning',
            data: data[1],
            type: 'scatter',
            itemStyle: {
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#DD5145'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 13
              }
              return 8
            }
          },
          {
            name: 'Total Assets',
            data: data[2],
            type: 'scatter',
            itemStyle: {
              shadowBlur: 6,
              shadowColor: 'rgba(68, 99, 1567, 0.45)',
              shadowOffsetY: 4,
              opacity: 1,
              color: function (params) {
                if (params.data && params.data[3] === '1') {
                  return '#DD5145'
                } else {
                  return params.color
                }
              }
            },
            symbolSize: function (data) {
              if (data && data[2]) {
                return Math.log(data[2]) + 12
              }
              return 8
            }
          },
          // {
          //   name: 'roa',
          //   data: data[3],
          //   type: 'scatter',
          //   itemStyle: {
          //     color: function (params) {
          //       if (params.data && params.data[3] === '1') {
          //         return '#DD5145'
          //       } else {
          //         return params.color
          //       }
          //     }
          //   },
          //   symbolSize: function (data) {
          //     if (data && data[3]) {
          //       return Math.log(data[3]) + 8
          //     }
          //     return 8
          //   }
          // },
        ]
      }
      this.echartsBox.setOption(option);
    },
    // srcviewscompanydetailsbusinessvueMethodEnd
  },
  mounted() {
    
    
    
    this.getList()
  },
  computed: {},
};
</script>
<style scoped>
.ua915f {
  margin-right: 0em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 0em;
  margin-left: 0em;
  margin-bottom: 0em;
}

.operations-head {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  height: 2.857142857142857rem;
  align-items: center;
}

.operations-content {
  padding: 2.142857142857143rem 2.857142857142857rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
}

.operations-box {
  margin-top: 2.142857142857143rem;
  display: flex;
  justify-content: space-between;
}

/deep/.el-descriptions__title {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
}

/deep/ .el-descriptions-item__label {
  display: block !important;
  margin-right: 2.142857142857143rem !important;
  /* padding-bottom: 2.142857142857143rem !important; */
}

.operations-label-title {
  width: 14.285714285714286rem;
  font-size: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  line-height: 1.8571428571428572rem;
}

.operations-label-text {
  font-size: 1.4285714285714286rem;
  font-family: "ArialMT";
  color: #29445d;
  line-height: 1.8571428571428572rem;
  width: 100%;
}

/deep/ .el-textarea__inner {
  font-size: 1.4285714285714286rem;
  color: #29445d;
}

.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}

.operations-box-left {
  margin-right: 4.285714285714286rem;
  /* width: 45%; */
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
  height: 3.5714285714285716rem;
  background: #F5F5F5;
  border-radius: 1.7857142857142858rem;
  display: flex;
  align-items: center;
  padding: 0.21428571428571427rem;


}

.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}

.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}

.icon {
  margin: 0 1.4285714285714286rem;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table .cell {
  /* font-size: 1.4285714285714286rem; */
  color: #1a2332;
  line-height: 2.142857142857143rem;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
  /* font-size: 1.4285714285714286rem; */
}

/deep/ .el-table {
  margin-top: 1.4285714285714286rem;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.table-content {
  height: 4rem !important;
  line-height: 4rem;
}

.operations-box-right {
  /* width:42.857142857142854rem; */
}

.svgbg {

  background-color: #FFFFFF;
}

.svgclass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.operations-box-lefttwo {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
  padding: 2.142857142857143rem 2.14rem;
  height: 100%;
}

.operations-headtwo {
  justify-content: flex-end;
  margin-bottom: 1.4285714285714286rem;
}

.el-select /deep/ .el-input__inner {
  height: 3.5714285714285716rem !important;
}

.juxingtu{
  flex: auto;
  height: 0px;
}

</style>